import React, {useEffect, useState} from 'react';
import Wrapper from '../Scenes/Wrapper';
import AdminContent from '../Scenes/AdminContent';
import SearchTable from '../Elements/SearchTable';
import {VolunteerEvents} from '../../FW/Models/VolunteerEvent';
import moment from 'moment';
import config from 'config';
import ExportVolunteerModal from './ExportVolunteerModal';

export default function VolunteerEventsView(props) {
  const [events, setEvents] = useState(new VolunteerEvents());
  const [startExport, setStartExport] = useState(false);

  return <Wrapper>
    {startExport ? <ExportVolunteerModal onSave={(startDate, endDate) => {
      setStartExport(false);
      window.location.href = config.API_ROOT + '/export-volunteers?startDate=' + startDate.format('YYYY-MM-DD') + '&endDate=' + endDate.format('YYYY-MM-DD');
    }} onCancel={()=>{
      setStartExport(false);
    }} /> : null}
    <AdminContent>
      <SearchTable
        title="Volunteer Events"
        collection={events}
        columns={{
          'Description': 'description',
          'Date': (v) => {
            return moment(v).format('MMM Do, YYYY');
          },
          'Volunteers': 'event_volunteers',
          'Beneficiaries': 'event_beneficiaries'
        }}
        deleteMessage="Are you sure you want to delete this event?"
        onEdit={(m)=>{
          props.history.push('/admin/volunteer-events/' + m.get('id'));
        }}
        onExport={() => {
          setStartExport(true);
        }}
      />
    </AdminContent>
  </Wrapper>
}
