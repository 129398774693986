import {MembershipCategory, MembershipCategories} from './MembershipCategory';
import {MembershipStatus, MembershipStatuses} from './MembershipStatus';
import {AppPage, AppPages} from './AppPage';
import {
  MembershipDirectoryMember,
  MembershipDirectoryMembers
} from './MembershipDirectoryMember';
import {
  MembershipDirectoryFamily,
  MembershipDirectoryFamilies
} from './MembershipDirectoryFamily';
import {
  MembershipDirectory,
  MembershipDirectories
} from './MembershipDirectory';
import {SiteBlueprint, SiteBlueprints} from './SiteBlueprint';
import {Comment, Comments} from './Comment';
import {FormFieldOption, FormFieldOptions} from './FormFieldOption';
import {FormResponseAnswer, FormResponseAnswers} from './FormResponseAnswer';
import {FormResponse, FormResponses} from './FormResponse';
import {FormField, FormFields} from './FormField';
import {Form, Forms} from './Form';
import {Model} from './Model';
import {Site, Sites} from './Site';
import {PageModel, Pages} from './Page';
import {PageGroup, PageGroups} from './PageGroup';
import {PageGroupLayout, PageGroupLayouts} from './PageGroupLayout';
import {PageGroupProperty} from './PageGroupProperty';
import {PageSection, PageSections} from './PageSection';
import {PageSectionLayout} from './PageSectionLayout';
import {PageSectionLayoutSetting} from './PageSectionLayoutSetting';
import {PageSectionType, PageSectionTypes} from './PageSectionType';
import {PageSectionTemplateLayout} from './PageSectionTemplateLayout';
import {Blog, Blogs} from './Blog';
import {BlogPost, BlogPosts} from './BlogPost';
import {ContentDefinition, ContentDefinitions} from './ContentDefinition';
import {
  ContentDefinitionGroup,
  ContentDefinitionGroups
} from './ContentDefinitionGroup';
import {User, Users} from './User';
import {File, Files} from './File';
import {Menu, Menus} from './Menu';
import MenuItem from './MenuItem';
import {MediaLibrary, MediaLibraries} from './MediaLibrary';
import {MediaSeries, MediaSeriesCollection} from './MediaSeries';
import {Tag, Tags} from './Tag';
import {FileCategory, FileCategories} from './FileCategory';
import {Collection} from './Collection';
import {PhotoGallery, PhotoGalleries} from './PhotoGallery';
import {BlogCategory, BlogCategories} from './BlogCategory';
import {UserRole, UserRoles} from './UserRole';
import {UserGroup, UserGroups} from './UserGroup';
import {UserPermission, UserPermissions} from './UserPermission';
import Customer from './Customer';
import {Template, Templates} from './Template';
import {FileType, FileTypes} from './FileType';
import {DomainName, DomainNames} from './DomainName';
import {SiteLogo, SiteLogos} from './SiteLogo';
import {UrlAlias, UrlAliases} from './UrlAlias';
import {DnsRecord, DnsRecords} from './DnsRecord';
import {Schedulable, Schedulables} from './Schedulable';
import {SchedulableGroup, SchedulableGroups} from './SchedulableGroup';
import {
  SchedulableReservationOverlap,
  SchedulableReservationOverlaps
} from './SchedulableReservationOverlap';
import {
  SchedulableReservation,
  SchedulableReservations
} from './SchedulableReservation';
import {LiveStreamChannel, LiveStreamChannels} from './LiveStreamChannel';
import {TemplateSetting} from './TemplateSetting';
import {PayPalGroup, PayPalGroups} from './PayPalGroup';
import {PayPalItem, PayPalItems} from './PayPalItem';
import {BlogSubscriber, BlogSubscribers} from './BlogSubscriber';
import {Questionnaire, Questionnaires} from './Questionnaire';
import {TpegUrl, TpegUrls} from './TpegUrl';
import {ChatMessage, ChatMessages} from './ChatMessage';
import {LiveStreamChannelStream, LiveStreamChannelStreams} from './LiveStreamChannelStream';
import {CommentWall, CommentWalls} from './CommentWall';
import {AppHomepageTiles, AppHomepageTile} from './AppHomepageTile';
import {ApplicationForm, ApplicationForms} from './ApplicationForm';
import {ApplicationFormPages, ApplicationFormPage} from './ApplicationFormPage';
import {ApplicationFormField, ApplicationFormFields} from './ApplicationFormField';
import {ApplicationFormFieldOption, ApplicationFormFieldOptions} from './ApplicationFormFieldOption';
import {Application, Applications} from './Application';
import {ApplicableItem, ApplicableItems} from './ApplicableItem';
import {ApplicationReference, ApplicationReferences} from './ApplicationReference';
import {ApplicationStatus, ApplicationStatuses} from './ApplicationStatus';
import {SiteOrder, SiteOrders} from './SiteOrder';
import {SiteBlueprintGroup, SiteBlueprintGroups} from './SiteBlueprintGroup';
import {Event, Events} from './Event';
import {EventCategory} from './EventCategory';
import {Calendar, Calendars} from './Calendar';
import {EventInstance, EventInstances} from './EventInstance';
import VolunteerEvents from '../../Administrator/VolunteerEvents/VolunteerEvents';
import {VolunteerEvent} from './VolunteerEvent';

/**
 * @ignore
 * @type {{Site: Site, Page: Page, PageGroup: PageGroup, PageGroups: PageGroups, PageRow: PageRow, PageRows: PageRows, PageSection: PageSection, PageSections: PageSections, PageSectionProperty: PageSectionProperty, PageSectionPropertiesCollection: PageSectionPropertiesCollection, PageSectionLayout: PageSectionLayout, PageSectionLayoutSetting: PageSectionLayoutSetting, Blog: Blog, Blogs: Blogs, PageGroupDefinition: ContentDefinition, ContentDefinitions: PageGroupDefinitions, PageGroupDefinitionType: ContentDefinitionGroup, ContentDefinitionGroups: PageGroupDefinitionTypes}}
 */
let Models = {
  MembershipCategory: MembershipCategory,
  MembershipCategories: MembershipCategories,
  MembershipStatus: MembershipStatus,
  MembershipStatuses: MembershipStatuses,
  AppPage: AppPage,
  AppPages: AppPages,
  MembershipDirectoryMember: MembershipDirectoryMember,
  MembershipDirectoryMembers: MembershipDirectoryMembers,
  MembershipDirectoryFamily: MembershipDirectoryFamily,
  MembershipDirectoryFamilies: MembershipDirectoryFamilies,
  MembershipDirectory: MembershipDirectory,
  MembershipDirectories: MembershipDirectories,
  SiteBlueprint: SiteBlueprint,
  SiteBlueprints: SiteBlueprints,
  Comment: Comment,
  Comments: Comments,
  FormFieldOption: FormFieldOption,
  FormFieldOptions: FormFieldOptions,
  FormResponseAnswer: FormResponseAnswer,
  FormResponseAnswers: FormResponseAnswers,
  FormResponse: FormResponse,
  FormResponses: FormResponses,
  FormField: FormField,
  FormFields: FormFields,
  Form: Form,
  Forms: Forms,
  Model: Model,
  Site: Site,
  Sites: Sites,
  Page: PageModel,
  PageModel: PageModel,
  Pages: Pages,
  PageGroup: PageGroup,
  PageGroups: PageGroups,
  PageGroupLayout: PageGroupLayout,
  PageGroupLayouts: PageGroupLayouts,
  PageGroupProperty: PageGroupProperty,
  PageSection: PageSection,
  PageSections: PageSections,
  PageSectionLayout: PageSectionLayout,
  PageSectionTemplateLayout: PageSectionTemplateLayout,
  PageSectionLayoutSetting: PageSectionLayoutSetting,
  PageSectionType: PageSectionType,
  PageSectionTypes: PageSectionTypes,
  Blog: Blog,
  Blogs: Blogs,
  BlogPost: BlogPost,
  BlogPosts: BlogPosts,
  ContentDefinition: ContentDefinition,
  ContentDefinitions: ContentDefinitions,
  ContentDefinitionGroup: ContentDefinitionGroup,
  ContentDefinitionGroups: ContentDefinitionGroups,
  User: User,
  Users: Users,
  File: File,
  Files: Files,
  FileType: FileType,
  FileTypes: FileTypes,
  Menu: Menu,
  Menus: Menus,
  MenuItem: MenuItem,
  MediaLibrary: MediaLibrary,
  MediaLibraries: MediaLibraries,
  MediaSeries: MediaSeries,
  MediaSeriesCollection: MediaSeriesCollection,
  Tag: Tag,
  Tags: Tags,
  Template: Template,
  Templates: Templates,
  TemplateSetting: TemplateSetting,
  FileCategory: FileCategory,
  FileCategories: FileCategories,
  Collection: Collection,
  PhotoGallery: PhotoGallery,
  PhotoGalleries: PhotoGalleries,
  BlogCategories: BlogCategories,
  BlogCategory: BlogCategory,
  UserRole: UserRole,
  UserRoles: UserRoles,
  UserGroup: UserGroup,
  UserGroups: UserGroups,
  UserPermission: UserPermission,
  UserPermissions: UserPermissions,
  Customer: Customer,
  DomainName: DomainName,
  DomainNames: DomainNames,
  SiteLogo: SiteLogo,
  SiteLogos: SiteLogos,
  UrlAlias: UrlAlias,
  UrlAliases: UrlAliases,
  DnsRecord: DnsRecord,
  Schedulable: Schedulable,
  Schedulables: Schedulables,
  SchedulableGroup: SchedulableGroup,
  SchedulableGroups: SchedulableGroups,
  SchedulableReservation: SchedulableReservation,
  SchedulableReservations: SchedulableReservations,
  LiveStreamChannel: LiveStreamChannel,
  LiveStreamChannels: LiveStreamChannels,
  PayPalGroup: PayPalGroup,
  PayPalGroups: PayPalGroups,
  PayPalItem: PayPalItem,
  PayPalItems: PayPalItems,
  SchedulableReservationOverlap: SchedulableReservationOverlap,
  SchedulableReservationOverlaps: SchedulableReservationOverlaps,
  BlogSubscriber: BlogSubscriber,
  BlogSubscribers: BlogSubscribers,
  Questionnaire: Questionnaire,
  Questionnaires: Questionnaires,
  TpegUrl: TpegUrl,
  TpegUrls: TpegUrls,
  ChatMessage: ChatMessage,
  ChatMessages: ChatMessages,
  LiveStreamChannelStream: LiveStreamChannelStream,
  LiveStreamChannelStreams: LiveStreamChannelStreams,
  CommentWall: CommentWall,
  CommentWalls: CommentWalls,
  AppHomepageTile: AppHomepageTile,
  AppHomepageTiles: AppHomepageTiles,
  ApplicationForms: ApplicationForms,
  ApplicationForm: ApplicationForm,
  ApplicationFormPage: ApplicationFormPage,
  ApplicationFormPages: ApplicationFormPages,
  ApplicationFormField: ApplicationFormField,
  ApplicationFormFields: ApplicationFormFields,
  ApplicationFormFieldOption: ApplicationFormFieldOption,
  ApplicationFormFieldOptions: ApplicationFormFieldOptions,
  Application: Application,
  Applications: Applications,
  ApplicableItem: ApplicableItem,
  ApplicableItems: ApplicableItems,
  ApplicationReference: ApplicationReference,
  ApplicationReferences: ApplicationReferences,
  ApplicationStatus: ApplicationStatus,
  ApplicationStatuses: ApplicationStatuses,
  SiteOrder: SiteOrder,
  SiteOrders: SiteOrders,
  SiteBlueprintGroup: SiteBlueprintGroup,
  SiteBlueprintGroups: SiteBlueprintGroups,
  Event: Event,
  Events: Events,
  Calendar: Calendar,
  Calendars: Calendars,
  EventCategory: EventCategory,
  EventInstance: EventInstance,
  EventInstances: EventInstances,
  VolunteerEvent: VolunteerEvent,
  VolunteerEvents: VolunteerEvents
};

export {Models};
