import React from 'react';
let Share;
import './shareButtons.scss';
import {File as FWFile} from 'FW/Models/File';
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkIcon from '@material-ui/icons/Link';
import { growl } from '@crystallize/react-growl';

class SocialButtons extends React.Component{

  constructor(props) {
    super(...arguments);

    this.state = {loaded: false};

    import('react-share').then((mod) => {
      Share = mod;
      this.setState({
        loaded: true
      });
    });

    this.image = props.picture ? FWFile.findOrCreate({id: props.picture}) : {};
    if (this.image.id) {
      this.image.fetch({
        success: () => {
          this.forceUpdate();
        }
      });
    }
  }

  render(){
    if (!this.state.loaded) return null;
    const size = this.props.size || 32;
    const round = !!this.props.round || !!this.props.shape;
    const disabled = this.props.disabled || '';
    const showShares = this.props.show_share_count;
    const style = {cursor: 'pointer', display: 'inline-block', margin: '5px', verticalAlign: 'top'};
    const description = this.props.description || '';
    const title = this.props.title || '';
    const hashtags = this.props.hashtags ? this.props.hashtags.constructor !== Array ? this.props.hashtags.split(' ') : this.props.hashtags : [];
    const image = this.image.id ? this.image.originalUrl() : null;
    return (
      <div className="shareButtons">
        {disabled.includes('Facebook') ? null :
          <Share.FacebookShareButton style={style} quote={description} className="shareButton" url={this.props.url}><Share.FacebookIcon size={size} round={round} />
            {showShares ? <Share.FacebookShareCount url={this.props.url}/> : null}
          </Share.FacebookShareButton>}
        {disabled.includes('LinkedIn') ? null : <Share.LinkedinShareButton description={description} title={title} style={style} className="shareButton" url={this.props.url}><Share.LinkedinIcon size={size} round={round} /></Share.LinkedinShareButton>}
        {disabled.includes('Twitter') ? null : <Share.TwitterShareButton title={title} hashtags={hashtags} style={style} className="shareButton" url={this.props.url}><Share.TwitterIcon size={size} round={round} /></Share.TwitterShareButton>}
        {disabled.includes('Telegram') ? null : <Share.TelegramShareButton style={style} title={title} className="shareButton" url={this.props.url}><Share.TelegramIcon size={size} round={round} /></Share.TelegramShareButton>}
        {disabled.includes('WhatsApp') ? null : <Share.WhatsappShareButton style={style} title={title} className="shareButton" url={this.props.url}><Share.WhatsappIcon size={size} round={round} /></Share.WhatsappShareButton>}
        {disabled.includes('Pinterest') || !image ? null : <Share.PinterestShareButton description={description} media={image} style={style} className="shareButton" url={this.props.url}><Share.PinterestIcon size={size} round={round} />
          {showShares ? <Share.PinterestShareCount url={this.props.url}/> : null}
        </Share.PinterestShareButton>}
        {disabled.includes('Reddit') ? null : <Share.RedditShareButton style={style} title={title} className="shareButton" url={this.props.url}><Share.RedditIcon size={size} round={round} />
          {showShares ? <Share.RedditShareCount url={this.props.url}/> : null}
        </Share.RedditShareButton>}
        {disabled.includes('Email') ? null : <Share.EmailShareButton style={style} className="shareButton" url={this.props.url}>
          <Share.EmailIcon size={size} round={round} />
        </Share.EmailShareButton>}
        <CopyToClipboard
          text={this.props.url}
          onCopy={async () => {
            const myGrowl = await growl({
              title: 'Share File',
              message: 'A link to this file has been copied to the clipboard.'
            });
          }}
          title="Copy link">
          <button className="react-share__ShareButton shareButton" style={{
              backgroundColor: '#000',
              border: 'none',
              padding: 0,
              front: 'inherit',
              cursor: 'pointer',
              display: 'inline-block',
              margin: 5,
              verticalAlign: 'top',
              width: 32,
              height: 32,
              color: '#FFF'
            }}>
            <LinkIcon style={{position: 'relative', top: 3}} />
          </button>
        </CopyToClipboard>
      </div>
    );
  }

}


export default SocialButtons;
